import { useSelector } from 'react-redux';

import { welcomeOfferSelector } from '@/domains/proB2BServices/platformConfig/platformConfig.selectors';
import type { PlatformConfigState } from '@/domains/proB2BServices/platformConfig/platformConfig.state';

import { isWelcomeOfferMultiCoupon } from '../utils/isWelcomeOfferMultiCoupon';

export type TypeWelcomeOffer = {
  amount: string | null;
  coupon: string | null;
  conditions: string | null;
  isExpired: boolean | null;
  isOfferMultiCoupon: boolean | null;
  minPurchaseAmount: string | null;
};

export const useWelcomeOffer = (): TypeWelcomeOffer => {
  const welcomeOffer = useSelector(
    welcomeOfferSelector,
  ) as PlatformConfigState['welcomeOffer'];
  const amount = welcomeOffer?.amount;
  const coupon = welcomeOffer?.coupon;
  const conditions = welcomeOffer?.conditions;
  const date = welcomeOffer?.end_date && welcomeOffer?.end_date.split('/');
  const endDate =
    date &&
    date.length &&
    new Date(Number(date[2]), Number(date[1]) - 1, Number(date[0]));
  const minPurchaseAmount = welcomeOffer?.min_purchase || null;

  // check if the endDate is expired
  const expired = !!(endDate && endDate < new Date());

  if (!amount || !coupon || !conditions) {
    return {
      amount: null,
      coupon: null,
      conditions: null,
      isExpired: null,
      isOfferMultiCoupon: null,
      minPurchaseAmount: null,
    };
  }

  const isOfferMultiCoupon = isWelcomeOfferMultiCoupon(amount);

  return {
    amount,
    coupon,
    conditions,
    isExpired: expired,
    isOfferMultiCoupon,
    minPurchaseAmount,
  };
};
