import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import type { SpartacuxProB2BServicesState } from '../store/store.interface';

export const platformConfigSelector = (state: SpartacuxProB2BServicesState) =>
  state.proB2BServices?.platformConfig;

export const isPlatformConfigLoading = createSelector(
  [platformConfigSelector],
  (platformConfig) => platformConfig?.isLoading ?? false,
);

export const mainPriceIncludesVATSelector = createSelector(
  [platformConfigSelector],
  (platformConfig): boolean => platformConfig?.mainPrice?.include_vat ?? false,
);

export const freeReturnsB2BSelector = createSelector(
  [platformConfigSelector],
  (platformConfig) => platformConfig?.freeReturnsB2B,
);

export const useFreeReturnsB2BSelector = () =>
  useSelector(freeReturnsB2BSelector);

export const couponsSelector = createSelector(
  [platformConfigSelector],
  (platformConfig) => platformConfig?.coupon ?? {},
);

export const welcomeOfferSelector = createSelector(
  [platformConfigSelector],
  (platformConfig) => platformConfig?.welcomeOffer ?? {},
);

export const welcomeOfferHvcSelector = createSelector(
  [platformConfigSelector],
  (platformConfig) => platformConfig?.welcomeOfferHvc,
);
