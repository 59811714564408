import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { pageNameSelector, pageTypeSelector } from '@/core/page/page.selectors';
import { routes } from '@/core/routing/routes/routes.default';
import { ManomanoNutLogoB2B as B2BLogo } from '@/core/tamagoshi/Logos/ManomanoNutLogoB2B/ManomanoNutLogoB2B';
import { Gtm } from '@/core/tracking/utils/Gtm';
import { Link } from '@/domains/core/routing/components/Link';
import { useWelcomeOffer } from '@/domains/proB2BAnimation/hooks/useWelcomeOffer';
import { GTM_CLICK_ON_PRO_BUTTON } from '@/productDiscovery/HeaderLeonidas/services/tracking/gtmEvents';

import { proButtonTitle, proOfferText } from './translations';

import styles from './ProButton.module.scss';

interface Props {
  className?: string;
}

export const ProButton: FunctionComponent<Props> = ({ className }) => {
  const pageType = useSelector(pageTypeSelector);
  const pageName = useSelector(pageNameSelector);
  const welcomeOffer = useWelcomeOffer();
  const proOfferTextValue = proOfferText.format({
    amount: welcomeOffer.amount ?? '',
  }) as string;

  return (
    <button
      type="button"
      className={classnames(styles.button, className)}
      data-testid="pro-button"
    >
      <Link
        href={routes.proLanding}
        className={styles.link}
        onClick={() => Gtm.push(GTM_CLICK_ON_PRO_BUTTON(pageType, pageName))}
        data-testid="pro-button-link"
      >
        <B2BLogo className={styles.logo} />
        <span className={styles.labels}>
          <span className={styles.title}>{proButtonTitle}</span>
          {!welcomeOffer.isExpired && proOfferTextValue && (
            <span data-testid="pro-offer-text" className={styles.offerText}>
              {proOfferTextValue}
            </span>
          )}
        </span>
      </Link>
    </button>
  );
};

ProButton.displayName = 'ProButton';
