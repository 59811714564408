import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import { useId, type FunctionComponent } from 'react';

export const ManomanoNutLogoB2B: FunctionComponent<SvgProps> = (props) => {
  const id = `b2bgradient-${useId()}`;

  return (
    <Svg optimizationId="iconsManomanoNutB2B" viewBox="0 0 42 43" {...props}>
      <>
        <path
          d="M27.6 0l-18 2.94203c-1.3.2029-2.4 1.11594-2.8 2.33333L.3 22.5217c-.2.5073-.3.9131-.3 1.3189 0 .8116.3 1.6232.8 2.3333l11.5 14.4058c.7.9131 1.8 1.3189 2.8 1.4203h.6l18-2.942c1.3-.2029 2.4-1.116 2.9-2.3334l6.5-17.2463c.5-1.2174.3-2.6377-.6-3.6522L31.1 1.42029C30.3.507246 29.3 0 28.2 0h-.6z"
          fill={`url(#${id})`}
        />
        <path
          d="M26.3001 12.4l-4.1 7.1-4-8.4-5 1.3-6.1 15.7h5.5l3.7-9.3 3.6 8.8h2.8l5.5-9.4v12.1l5.1-1.7v-15l-7-1.2z"
          fill="#fff"
        />
        <defs>
          <linearGradient
            id={id}
            x1="0"
            y1="41.9811"
            x2="43.3739"
            y2="41.9811"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E6685A" />
            <stop offset=".00044188" stopColor="#E6685A" />
            <stop offset="1" stopColor="#FFB950" />
          </linearGradient>
        </defs>
      </>
    </Svg>
  );
};

ManomanoNutLogoB2B.displayName = 'ManomanoNutLogoB2B';
