// We use 'x' or 'X' chars to indicate that the welcome offer is a multi-coupon offer.
// For example 3 x 20€
const MultiCouponRegex = /[Xx]/g;

export const isWelcomeOfferMultiCoupon = (amount: string | null): boolean => {
  if (!amount) {
    return false;
  }

  return new RegExp(MultiCouponRegex).test(amount);
};
